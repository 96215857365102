<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-center">
        <h2 class="display-2 d-inline-block">Seite nicht gefunden</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
